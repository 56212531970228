<template>

  <b-container fluid id="app" class="body-content cover-full"> 
    
      <!-- NAVBAR-->
      <b-row align-v="center">
        <b-col sm="12">
          <b-navbar  toggleable="md"  sticky fixed="top" style="background-color: rgba(255, 255, 255, 0.7); " >
            <b-navbar-brand to="" class="mx-3">
              <img src="https://www.teotihuacan.gob.mx/turismot/logoteo.png" style="height: 35px;" class="d-inline-block align-top" >
            </b-navbar-brand>  
          
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav  class="mx-auto">
                <b-nav-item @click="$router.push('/home')"  class="mx-4 " ><b class="items-nav titles"> Inicio</b></b-nav-item >
                <b-nav-item @click="$router.push('/hoteles')"  class="mx-4"><b class="items-nav titles"> Hoteles</b></b-nav-item>
                <b-nav-item @click="$router.push('/restaurantes')" class="mx-4"><b class="items-nav titles"> Restaurantes</b></b-nav-item>
                <b-nav-item @click="$router.push('/temazcales')"  class="mx-4"><b class="items-nav titles"> Temazcales</b></b-nav-item>
                <b-nav-item @click="$router.push('/atracciones')"  class="mx-4"><b class="items-nav titles"> Actividades</b></b-nav-item>

              </b-navbar-nav>               
            </b-collapse>
              
          </b-navbar>
        </b-col>
      </b-row>

    <b-row align="center" align-h="center" style="min-height:780px;">
      <b-col cols="12" >
        <router-view/>
      </b-col>
    </b-row>

   

    <b-modal v-model="showAvisoDePrivacidad" size="lg" title="Aviso De Privacidad" hide-footer>
      <p>{{resources.aviso_privacidad}}</p>
    </b-modal>

    <b-modal v-model="showTerminosYCondiciones" size="lg" title="Terminos Y Condiciones" hide-footer>
      <p>{{resources.terminos_condiciones}}</p>
    </b-modal>

    <!-- <b-modal header-bg-variant="dark"  body-bg-variant="dark" v-model="showModalPrincipal" size="lg" hide-header hide-footer>
      <b-row no-gutters>
        <b-col style="border:solid;">
          <div style="border:solid;">
            <b-embed        
              style="width:100%; height:600px;"
              type="iframe"
              :src="resources.contenido_modal.url"
            ></b-embed>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center" align="center">
        <b-col>
          <b-button pill size="sm" @click="showModalPrincipal=false">
            Cerrar
          </b-button>
        </b-col>
      </b-row>
    </b-modal> -->
  </b-container>
</template>


<script>

  export default {
    mounted() {

    },
    data() {
      return {
        showAvisoDePrivacidad:false,
        showTerminosYCondiciones:false,
        resources:{
          domicilio:"",
        },
        showModalPrincipal:true

      }
    }
  };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#footer {
  background: linear-gradient(90deg, rgba(255,31,31,1) 0%, rgba(220,0,0,1) 100%);
  color: #fff;
}

/*
.parallax {
 
  background-image: url("assets/teoprincipal.jpg");

  margin-top: -260px;
  
  
  min-height: 1000px;

  
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
*/


.img {
  border-radius: 20px;
  height: 300px;
  width: 300px;
  box-shadow: 5px 5px 20px -2px #5f5f5f;
}

.img-carousel{
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.body-content {
  background-color: white;
}

.target-card {
  background-color: red;
  border-radius: 2em;
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 5px rgb(170, 163, 163);
  color: #fff;
}

.cover-full {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}

@font-face {
  font-family: 'Crossten Extrabold';
  src: url('assets/fonts/Horizon Type - Crossten Extrabold.otf') 

}

.titles {
  font-family: 'Crossten Extrabold';
}

.items-nav {
  color:rgb(229,0,0);
}

.social-links{ 
  color: white !important; 
  text-decoration: none;
}

.social-icons {
  width: 20px; 
  height: 20px;
}

.icons-cards {
  width: 120px; height: 120px;
}


/* styles flipbook*/


.action-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar .btn {
  font-size: 30px;
  color: black;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: black;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: black;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}




@media (min-width: 871px)  {
  .offset-flipbook {
    margin-bottom:15px;
  }
}
@media (min-width: 781px) and (max-width: 870px) {
  .offset-flipbook {
    margin-bottom:10px;
  }
}
@media (min-width: 641px) and (max-width: 780px) {
  .offset-flipbook {
    margin-bottom:-20px;
  }
}
@media (min-width: 589px) and (max-width: 640px) {
  .offset-flipbook {
    margin-bottom:-75px;
  }
}
@media (min-width: 521px) and (max-width: 588px) {
  .offset-flipbook {
    margin-bottom:15px;
  }
}
@media (min-width: 451px) and (max-width: 520px) {
  .offset-flipbook {
    margin-bottom:30px;
  }
}
@media (max-width: 450px) {
  .offset-flipbook {
    margin-bottom: -10px;
  }
}



@media (min-width: 872px)  {
  .offset-button-flipbook {
    margin-top:75px;
  }
}
@media (min-width: 781px) and (max-width: 871px) {
  .offset-button-flipbook {
    margin-top:75px;
  }
}
@media (min-width: 641px) and (max-width: 780px) {
  .offset-button-flipbook {
     margin-top:-10px;
  }
}
@media (min-width: 589px) and (max-width: 640px) {
  .offset-button-flipbook {
    margin-top: -115px;
  }
}
@media (min-width: 521px) and (max-width: 588px) {
  .offset-button-flipbook {
    margin-top: 70px;
  }
}

@media (min-width: 451px) and (max-width: 520px) {
  .offset-button-flipbook {
    margin-top:75px;
  }
}

@media (max-width: 450px) {
  .offset-button-flipbook {
    margin-top: 10px;
  }
}




.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

}
.action-bar .btn {
  font-size: 30px;
  color: black;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: black;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: black;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}


.flipbook .viewport {
  width: 200vw;
  /*height: calc(100vh - 50px - 40px);*/
  height: 100vh;
}

.flipbook {
  height: 90vh;
}

.flipbook .bounding-box {
  box-shadow: 5px 5px 20px -2px #5f5f5f;
}


#flipbook-body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: #ccc;
}

.c-shadow-sm{
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  margin-bottom:10px;
  border-radius:25px !important;
}
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #5f5f5f;
  border-radius: 50%;
  font-size:20px;
  padding:20px;
  width: 98px;
  height: 98px;
  position:absolute;
  top:-49px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}


.imagebackground1{
  border-radius: 20px;
  background-image: url("assets/plaza1.jpg");
}

.imagebackground2{
  border-radius: 20px;
  background-image: url("assets/plaza2.jpg");
}

.imagebackground3{
  border-radius: 20px;
  background-image: url("assets/plaza3.jpg");
}

.redtransparent{
  border-radius: 20px;
  background:rgba(255, 0, 0, 0.671); 
  padding:7%;
  }

</style>


